<template>
  <cw-page
    icon="check"
    class="cw-brokering-complete"
  >
    <v-row>
      <v-col>
        <p>
          <translate>
            The application process is completed.
            You can apply for a new loan from the link.
          </translate>
        </p>
      </v-col>
    </v-row>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="new-application__button"
        color="primary"
        href="https://www.vippi.fi"
        @click="$eventLogger.clickEvent($event)"
      >
        <translate>
          Apply for a loan
        </translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
export default {
  name: 'CwBrokeringComplete',
};
</script>

<style lang="scss">
.new-application {
  &__content {
    max-width: 600px;
  }
}
</style>
